<script>
import { SuawMainContent, SuawSection, SuawTabs, SuawDivider, SuawEmptyState } from "@suaw/suaw-component-library";
import { GetChapterOrganizersById, GetChapterChannelsById, GetChaptersByIds } from "../../operations.gql";
import ChapterSummaryPipe from "../components/ChapterSummaryPipe.vue";
import ChapterThumbnailPipe from "../components/ChapterThumbnailPipe.vue";
import ChapterDescriptionPipe from "../components/ChapterDescriptionPipe.vue";
import UpcomingChapterEventsListPipe from "../components/UpcomingChapterEventsListPipe.vue";
import NewChapterMembersListPipe from "../components/NewChapterMembersListPipe.vue";
import DiscussionListPipe from "../../../discussions/components/DiscussionListPipe.vue";
import ChapterEventsListPipe from "../components/ChapterEventsListPipe.vue";
import ChapterMembersListPipe from "../components/ChapterMembersListPipe.vue";
export default {
  name: "Chapter",
  components: {
    SuawMainContent,
    SuawSection,
    SuawTabs,
    SuawDivider,
    SuawEmptyState,
    ChapterSummaryPipe,
    ChapterThumbnailPipe,
    ChapterDescriptionPipe,
    UpcomingChapterEventsListPipe,
    NewChapterMembersListPipe,
    DiscussionListPipe,
    ChapterEventsListPipe,
    ChapterMembersListPipe
  },
  data() {
    return {
      loadingQueriesCount: 0,
      activeTabIndex: 0,
      resultGetChapterOrganizersById: [
        // {
        //   user_id: ""
        // }
      ],
      resultGetChapterChannelsById: {
        id: "",
        public_channel: [
          // {
          //   channel: {
          //     id: ""
          //   }
          // }
        ],
        organizer_channel: [
          // {
          //   channel: {
          //     id: ""
          //   }
          // }
        ]
      },
      resultGetChaptersByIds: [
        // {
        //   id: "",
        //   slug: "",
        //   title: "",
        //   photo_url: ""
        // }
      ]
    };
  },
  apollo: {
    resultGetChapterOrganizersById: {
      query: GetChapterOrganizersById,
      variables() {
        return {
          chapterId: this.$route.params.chapterId
        };
      },
      skip() {
        return !this.$route.params.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultGetChapterChannelsById: {
      query: GetChapterChannelsById,
      variables() {
        return {
          chapterId: this.$route.params.chapterId
        };
      },
      skip() {
        return !this.$route.params.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultGetChaptersByIds: {
      query: GetChaptersByIds,
      variables() {
        return {
          ids: [this.$route.params.chapterId]
        };
      }
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasChapterOrganizers() {
      return this.resultGetChapterOrganizersById.length > 0;
    },
    chapterOrganizers() {
      return this.hasChapterOrganizers ? this.resultGetChapterOrganizersById : null;
    },
    isOrganizer() {
      if (!this.$auth.isAuthenticated) return false;
      return this.chapterOrganizers?.some(organizer => organizer.user_id === this.$auth.user.id);
    },
    hasChapterChannels() {
      return !!this.resultGetChapterChannelsById.id;
    },
    chapterChannel() {
      return this.hasChapterChannels ? this.resultGetChapterChannelsById : null;
    },
    chapterPublicChannel() {
      return this.chapterChannel?.public_channel?.[0]?.channel?.id || null;
    },
    chapterOrganizerChannel() {
      return this.chapterChannel?.organizer_channel?.[0]?.channel?.id || null;
    },
    tabNames() {
      const tabs = [{ label: "Chapter Summary" }, { label: "Events" }, { label: "Members" }, { label: "Discussions" }];
      if (this.isOrganizer) {
        tabs.push({ label: "Organizer Discussions" });
      }
      return tabs;
    },
    showEditorPublicChannel() {
      return this.$auth.isAuthenticated;
    },
    isGlobalOnlineChapter() {
      return this.resultGetChaptersByIds[0]?.slug === "shutupandwriteonlineevents";
    }
  },
  methods: {
    onViewAllClick(activeTab) {
      this.activeTabIndex = activeTab;
      const suawTabsElement = this.$refs.suawTabs.$el;
      suawTabsElement.scrollIntoView({ behavior: "auto" });
    }
  }
};
</script>

<template>
  <SuawMainContent v-if="isGlobalOnlineChapter" size="large">
    <h1>Oops!</h1>
    <p>
      We can't find the page you're looking for.
      <br />
      <router-link :to="{ name: 'Home' }">Go Home</router-link>
    </p>
  </SuawMainContent>
  <SuawMainContent v-else size="large-small-gap">
    <SuawSection>
      <ChapterSummaryPipe :chapter-id="$route.params.chapterId" :is-organizer="isOrganizer" />
    </SuawSection>
    <SuawSection>
      <ChapterThumbnailPipe :chapter-id="$route.params.chapterId" />
    </SuawSection>
    <SuawSection>
      <SuawTabs ref="suawTabs" v-model="activeTabIndex" :tabs="tabNames" tabs-type="lines">
        <template #tab-0>
          <ChapterDescriptionPipe :chapter-id="$route.params.chapterId" />
          <SuawDivider />
          <UpcomingChapterEventsListPipe :chapter-id="$route.params.chapterId" @view-all-click="onViewAllClick(1)" />
          <SuawDivider />
          <NewChapterMembersListPipe :chapter-id="$route.params.chapterId" @view-all-click="onViewAllClick(2)" />
          <SuawDivider />
          <DiscussionListPipe
            :show-editor="false"
            :channel-id="chapterPublicChannel"
            :thread-count="2"
            :show-header="true"
            header-label="Recent Discussions"
            button-label="View All Discussions"
            @view-all-click="onViewAllClick(3)"
          />
        </template>
        <template #tab-1>
          <ChapterEventsListPipe :chapter-id="$route.params.chapterId" />
        </template>
        <template #tab-2>
          <ChapterMembersListPipe :chapter-id="$route.params.chapterId" />
        </template>
        <template #tab-3>
          <SuawEmptyState v-if="!showEditorPublicChannel" message="Join Chapter to Create a Discussion" />
          <DiscussionListPipe :show-editor="showEditorPublicChannel" :channel-id="chapterPublicChannel" :thread-count="0" />
        </template>
        <template #tab-4>
          <DiscussionListPipe :show-editor="true" :channel-id="chapterOrganizerChannel" :thread-count="0" />
        </template>
      </SuawTabs>
    </SuawSection>
  </SuawMainContent>
</template>
