<script>
import { SuawInputGroup, SuawSectionHeader, SuawListSection, SuawChapterEventCard, SuawEmptyState } from "@suaw/suaw-component-library";
import { GetChapterEventsById, GetUserChapterEventsRsvpStatus } from "../../operations.gql";

export default {
  name: "UpcomingChapterEventsListPipe",
  components: { SuawInputGroup, SuawSectionHeader, SuawListSection, SuawChapterEventCard, SuawEmptyState },
  props: {
    chapterId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultGetChapterEventsById: {
        id: "",
        upcomingEvents: [
          // {
          //   events: [
          //     {
          //       id: "",
          //       starts_at: "",
          //       ends_at: "",
          //       is_cancelled: false,
          //       is_virtual: false,
          //       venue_json: {
          //         virtualUrl: "",
          //         virtualMeetingId: "",
          //         virtualMeetingPassword: "",
          //         physicalTitle: "",
          //         physicalAddress: ""
          //       },
          //       event_series: {
          //         id: "",
          //         slug: "",
          //         title: "",
          //         organizers: [
          //           {
          //             user: {
          //               id: "",
          //               display_name: "",
          //               initials: "",
          //               avatar_url: "",
          //               avatar_color: "",
          //               is_avatar_hidden: false
          //             }
          //           }
          //         ],
          //         chapter: {
          //           id: "",
          //           slug: ""
          //         }
          //       }
          //     }
          //   ]
          // }
        ]
      },
      resultGetUserChapterEventsRsvpStatus: [
        // {
        //   id: "",
        //   event_occurrence_id: ""
        // }
      ]
    };
  },
  apollo: {
    resultGetChapterEventsById: {
      query: GetChapterEventsById,
      variables() {
        return {
          id: this.chapterId,
          endsAfter: new Date().toISOString(),
          includeCancelled: false
        };
      },
      skip() {
        return !this.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultGetUserChapterEventsRsvpStatus: {
      query: GetUserChapterEventsRsvpStatus,
      variables() {
        return {
          chapterId: this.chapterId,
          userId: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasChapter() {
      return !!this.resultGetChapterEventsById.id;
    },
    chapter() {
      return this.hasChapter ? this.resultGetChapterEventsById : null;
    },
    hasRsvpStatus() {
      return this.resultGetUserChapterEventsRsvpStatus.length > 0;
    },
    rsvpedEventIds() {
      return this.hasRsvpStatus ? this.resultGetUserChapterEventsRsvpStatus.map(rsvp => rsvp.event_occurrence_id) : null;
    },
    upcomingEvents() {
      if (!this.chapter || !this.chapter.upcomingEvents) return [];
      // Flatten all event occurrences from the event_series
      const sortedEvents = this.chapter.upcomingEvents.flatMap(series => series.events).sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at));
      return sortedEvents;
    },
    transformedEvents() {
      return this.upcomingEvents?.map(event => {
        const startsAtDate = new Date(event.starts_at);
        const endsAtDate = new Date(event.ends_at);

        // Options for formatting time with space
        const timeOptionsWithSpace = { hour: "numeric", minute: "numeric", hour12: true };

        // Format the start time for CalendarDate with lowercase "am/pm"
        const startTime = startsAtDate.toLocaleTimeString([], timeOptionsWithSpace).replace(/([AP]M)/, match => match.toLowerCase());

        // Get start and end times without space for the duration
        const startTimeNoSpace = startsAtDate
          .toLocaleTimeString([], timeOptionsWithSpace)
          .replace(/ /g, "")
          .toLowerCase();

        const endTimeNoSpace = endsAtDate
          .toLocaleTimeString([], timeOptionsWithSpace)
          .replace(/ /g, "")
          .toLowerCase();

        const duration = `${startTimeNoSpace} - ${endTimeNoSpace}`; // Concatenate times without space

        // Determine location based on whether the event is virtual or not
        const location = event.is_virtual ? "Online Event" : event.venue_json.physicalTitle;
        // Determine event status
        const status = event.is_cancelled ? "cancelled" : new Date(event.ends_at) < new Date() ? "past" : event.is_virtual ? "online" : "in-person";
        // Transform the organizers array
        const organizers = event.event_series.organizers.map(org => ({
          id: org.user.id,
          avatar_url: org.user.avatar_url,
          avatar_color: org.user.avatar_color,
          initials: org.user.initials,
          display_name: org.user.display_name,
          is_avatar_hidden: org.user.is_avatar_hidden
        }));

        return {
          id: event.id,
          title: event.event_series.title,
          status: status,
          month: startsAtDate.getMonth() + 1,
          day: startsAtDate.getDate(),
          isVirtual: event.is_virtual,
          startTime: startTime, // Time for CalendarDate with lowercase am/pm
          duration: duration, // Duration without space between time and am/pm
          location: location,
          organizers: organizers,
          rsvped: this.rsvpedEventIds ? this.rsvpedEventIds.includes(event.id) : false,
          seriesSlug: event.event_series.slug,
          chapterSlug: event.event_series.chapter.slug
        };
      });
    }
  },
  methods: {
    onCardClick(card) {
      this.$router.push({
        name: "Series",
        params: {
          chapterSlug: card.chapterSlug,
          eventId: card.id,
          seriesSlug: card.seriesSlug
        }
      });
    }
  }
};
</script>

<template>
  <SuawInputGroup direction="column" group-gap="double">
    <SuawSectionHeader header-label="Upcoming Events" button-label="View All Events" @view-all-click="$emit('view-all-click')" />
    <SuawListSection v-if="transformedEvents.length > 0">
      <SuawChapterEventCard
        v-for="event in transformedEvents.slice(0, 2)"
        :id="event.id"
        :key="event.id"
        :title="event.title"
        :status="event.status"
        :month="event.month"
        :day="event.day"
        :start-time="event.startTime"
        :is-virtual="event.isVirtual"
        :duration="event.duration"
        :location="event.location"
        :organizers="event.organizers"
        :is-rsvped="event.rsvped"
        :series-slug="event.seriesSlug"
        :chapter-slug="event.chapterSlug"
        @click="onCardClick"
      />
    </SuawListSection>
    <SuawEmptyState v-else message="No Upcoming Events" />
  </SuawInputGroup>
</template>
