<script>
import { SuawInputGroup, SuawSectionHeader, SuawListSection, SuawChapterMemberCard, SuawEmptyState } from "@suaw/suaw-component-library";
import { GetChapterMembersListById } from "../../operations.gql";
export default {
  name: "NewChapterMembersListPipe",
  components: { SuawInputGroup, SuawSectionHeader, SuawListSection, SuawChapterMemberCard, SuawEmptyState },
  props: {
    chapterId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultGetChapterMembersListById: [
        // {
        //   id: "",
        //   user_id: "",
        //   chapter_member_role: "",
        //   user: {
        //     id: "",
        //     first_name: "",
        //     display_name: "",
        //     initials: "",
        //     avatar_url: "",
        //     avatar_color: ""
        //   }
        // }
      ]
    };
  },
  apollo: {
    resultGetChapterMembersListById: {
      query: GetChapterMembersListById,
      variables() {
        return {
          chapterId: this.chapterId
        };
      },
      skip() {
        return !this.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasChapter() {
      return !!this.resultGetChapterMembersListById.length > 0;
    },
    chapter() {
      return this.hasChapter ? this.resultGetChapterMembersListById : null;
    },
    chapterMembers() {
      const authenticatedUserId = this.$auth.isAuthenticated ? this.$auth.user.id : null;
      const isUserOrganizer = this.resultGetChapterMembersListById.some(
        member => authenticatedUserId && member.user_id === authenticatedUserId && member.chapter_member_role === "organizer"
      );

      return (
        this.chapter?.map(member => ({
          userId: member.user.id,
          avatarUrl: member.user.avatar_url,
          avatarColor: member.user.avatar_color,
          initials: member.user.initials,
          displayName: member.user.display_name,
          isAvatarHidden: member.user.is_avatar_hidden,
          isOrganizer: member.chapter_member_role === "organizer",
          // Allow messaging if the member is an organizer or the authenticated user is an organizer, but not if the member is the authenticated user
          canMessage: authenticatedUserId ? (member.chapter_member_role === "organizer" || isUserOrganizer) && member.user.id !== authenticatedUserId : false,
          location: member.user.country,
          eventCount: member.user.event_count?.aggregate?.count || 0,
          postCount: member.user.post_count?.aggregate?.count || 0
        })) || []
      );
    }
  },
  methods: {
    onProfileClick(userId) {
      this.$router.push({
        name: "UserProfile",
        params: {
          userId: userId
        }
      });
    },
    onMessageClick(userId) {
      if (this.$auth.isAuthenticated && this.$auth.user.id !== userId) {
        this.$root.$emit("open-direct-message", { otherUserId: userId });
      }
    }
  }
};
</script>

<template>
  <SuawInputGroup direction="column" group-gap="base">
    <SuawSectionHeader header-label="New Members" button-label="View All Members" @view-all-click="$emit('view-all-click')" />
    <SuawListSection v-if="chapterMembers.length > 0" list-direction="horizontal">
      <SuawChapterMemberCard
        v-for="member in chapterMembers.slice(0, 10)"
        :key="member.userId"
        :user-id="member.userId"
        :avatar-url="member.avatarUrl"
        :avatar-color="member.avatarColor"
        :avatar-initials="member.initials"
        :is-avatar-hidden="member.isAvatarHidden"
        :display-name="member.displayName"
        :location="member.location"
        :is-organizer="member.isOrganizer"
        :can-message="member.canMessage"
        :event-count="member.eventCount"
        :post-count="member.postCount"
        @profile-click="onProfileClick"
        @message-click="onMessageClick"
      />
    </SuawListSection>
    <SuawEmptyState v-else message="No New Members" />
  </SuawInputGroup>
</template>
